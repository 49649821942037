import type { OgType, Seo } from "~/types/seo";

/**
 * Sets meta tags with `useSeoMeta` and returns the wep page attributes
 * for schema that should be used in all pages.
 */
export function useSeo(data: Seo, translationParams?: TranslationParams) {
  const { siteUrl } = useRuntimeConfig().public;
  const { localeProperties, availableLocales } = useI18n();
  const { getLocalePath } = usePath();
  const route = useRoute();

  // We need to remove the trailing / otherwise it will be duplicated in the schema.org
  const sanitizedIndexPath = getLocalePath("index") === "/" ? "" : getLocalePath("index");

  // Set meta tags
  useSeoMeta({
    robots: () => data.robots?.reverse().join(", "),
    title: () => data.title!, // mapSeo already provides a default value
    description: () => data?.description ?? "",
    ogTitle: () => data?.title ?? "",
    ogDescription: () => data.description,
    ogImageUrl: () => data.openGraph?.image?.secureUrl ?? imagePlacholder.blog,
    ogImageSecureUrl: () => data.openGraph?.image?.secureUrl ?? imagePlacholder.blog,
    ogImageAlt: () => data.openGraph?.image ? data.title as string : "Grape Law Firm",
    ogType: () => data.openGraph?.type as OgType || "website",
    twitterCard: () => "summary_large_image",
    twitterTitle: () => data.title,
    twitterDescription: () => data.description,
    twitterImage: () => data.openGraph?.image?.secureUrl ?? imagePlacholder.blog,
    twitterImageAlt: () => data.title as string,
  });

  // Generate i18n parameters
  if (translationParams) {
    const i18nParams: Record<string, TranslationData> = availableLocales.reduce((acc, loc) => {
      if (translationParams && loc in translationParams) {
        acc[loc] = Object.keys(translationParams[loc]).reduce((locAcc, key) => {
          locAcc[key] = translationParams[loc][key];
          return locAcc;
        }, {} as TranslationData);
      }
      else {
        acc[loc] = Object.keys(translationParams[Object.keys(translationParams)[0]]).reduce((locAcc, key) => {
          locAcc[key] = undefined;
          return locAcc;
        }, {} as TranslationData);
      }
      return acc;
    }, {} as Record<string, TranslationData>);

    const setI18nParams = useSetI18nParams();
    setI18nParams(i18nParams);
  }

  // Set head attributes that require localization
  const i18nHead = useLocaleHead({
    addSeoAttributes: true,
    identifierAttribute: "id",
  });

  // Check if x-default is present and create it if it's missing
  const hasXDefault = i18nHead.value.link?.some((link: any) => link.hreflang === "x-default");
  const xDefaultLink = !hasXDefault
    ? {
        id: "i18n-xd",
        rel: "alternate",
        // href: `${siteUrl}${defaultLocale}${route.fullPath}`,
        href: `${siteUrl}${route.path}`,
        hreflang: "x-default",
      }
    : null;

  useHead({
    htmlAttrs: {
      lang: i18nHead.value.htmlAttrs!.lang,
    },
    link: [
      ...(i18nHead.value.link || []),
      ...(xDefaultLink ? [xDefaultLink] : []),
    ],
    meta: [...(i18nHead.value.meta || [])],
  });

  // Return the web page attributes for schema.org
  return {
    // id and url are provided by the schema.org plugin
    schemaWebPage: {
      name: data.title,
      datePublished: data.openGraph?.articleMeta?.publishedTime ?? undefined,
      dateModified: data.openGraph?.updatedTime || data.openGraph?.articleMeta?.modifiedTime,
      about: { "@id": `${siteUrl}${sanitizedIndexPath}/#organization` },
      isPartOf: { "@id": `${siteUrl}${sanitizedIndexPath}/#website` },
      inLanguage: localeProperties.value.iso,
    },
  };
}

export type TranslationParams = Record<string, TranslationData>;

interface TranslationData {
  [key: string]: string | undefined;
}
